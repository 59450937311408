import { get } from "lodash";
import { useInstanceEvent, useStoreContext } from "../../context/store";

const Header = () => {
  const { context } = useStoreContext();

  const {
    poll,
    campaign,
  } = context.data;

  const settings = useInstanceEvent('settings', instance => instance.settings);
  const showLogo = get(poll, 'metadata.settings.showLogo', true);

  const {header} = settings.ui ?? {};

  return (
    <div className={'principal'}>
    {get(campaign, 'src') && showLogo && !header ? (
      <div style={{ marginTop: '15px' }} className={'pregunta'}>
        <img alt="logo" className={'imagen'} src={campaign.src} />
      </div>
    ) : null}

    {header && (
      <div style={{ width: '100%' }}>
        <img
          className="header-img"
          style={{ width: '100%', marginBottom: '10px' }}
          src={header}
        />
      </div>
    )}
  </div>
  );
}

export default Header;
