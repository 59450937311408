const RadioLayout = ({ question, completNumeric, numericValue, min, max, answer, setNumericScore  }) => {
    return (
        <ul className={`numericStyle`}>
          {completNumeric.map(values => {
            return (
              <li
                className={numericValue === values.score && 'select'}
                key={values.score}
                data-testid={`${question.id}${values.score === 'N/A' ? null : values.score}`}
                onClick={() => setNumericScore(values.score, min, max)}
              >
                {values.score}

                {answer &&
                answer.module.id === question.id &&
                numericValue === values.score ? (
                  <i className={'smile21-radio-checked'} />
                ) : (
                  <i className={'smile21-radio-unchecked'} />
                )}
              </li>
            );
          })}
        </ul>
    )
}

export default RadioLayout