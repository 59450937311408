/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useStoreContext } from '../../context/store';

const storeVariables = ['name'];
const contactVariables = ['first_name', 'last_name'];

const replaceVariables = (text, store, contact) => {
  if (!text) return text;

  const replaceVariable = (variable, data) => {
    const placeholder = `{{ ${variable} }}`;
    return text.includes(placeholder) ? text.replace(new RegExp(placeholder, 'g'), data) : text;
  };

  storeVariables.forEach(variable => {
    text = replaceVariable(`store.${variable}`, get(store, variable, ''));
  });

  contactVariables.forEach(variable => {
    text = replaceVariable(`contact.${variable}`, get(contact, variable, ''));
  });

  return text;
}

const Presentation = ({ informationModule, question }) => {
  const { context } = useStoreContext();
  const { poll, store, contact } = context.data;
  const { metadata } = poll;
  const informationPath = get(question, 'metadata.settings', '');

  const { title, subtitle, text, image } = useMemo(() => {
    return get(informationModule ? informationPath : metadata, informationModule ? 'information' : 'settings.presentation', {});
  }, [metadata, informationModule, informationPath]);

  return (
    <div
      id="presentation"
      data-testid="presentation"
      style={{ marginTop: '50px', padding: '0px 20px 0px 20px' }}
    >
      {get(image, 'src') && <img
        src={get(image, 'src')}
        alt='presentation'
        style={{
          marginBottom: get(image, 'marginBottom'),
          marginTop: get(image, 'marginTop'),
          width: get(image, 'width', '100%'),
          height: get(image, 'height', '100%')
        }}
      />}
      {title && (
        <h3 data-testid="presentation-title" className={'presentation-title'}>
          {replaceVariables(title, store, contact)}
        </h3>
      )}
      {subtitle && <h5 className={'presentation-subtitle'}>{subtitle}</h5>}
      {text && <div
        className={'presentation-text'}
        style={{ whiteSpace: 'pre-wrap', fontSize: '19px' }}
        data-testid='presentation-text'
      >
        {text}
      </div>}
    </div>
  );
};

export default Presentation;
