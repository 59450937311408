/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect, useMemo } from 'react';
import logoDefault from '../assets/new-logo.png';
import logoDefaultWhite from '../assets/logo-blanco.png';
import poweredBySmile from '../assets/powered-by-smile.png';
import poweredBySmileWhite from '../assets/powered-by-smile-blanco.png';
import { useStoreContext } from '../context/store';
import Button from '@material-ui/core/Button';
import ButtonShard from '../components/ui/Button';
import TypewriterComponent from 'typewriter-effect';
import clsx from 'clsx';

import { useI18n } from '../utils/i18n';

import get from 'lodash/get';
import merge from 'lodash/merge';
import '../assets/thanks.scss';

import { bridge } from '../utils/bridge';
import { useFormData } from '../hooks/form';
import { replaceVariables, variablesToReplace } from '../utils/replaceVariables';

const Thanks = ({ data }) => {
  useFormData(data);
  const { context, instance } = useStoreContext();
  const { events } = context.data;
  const [showEvents, setShowEvents] = useState(false);
  const [showingForm, setShowingForm] = useState(undefined);
  const replaceVariableError = false;
  const [cursorRemoved, setCursorRemoved] = useState(false)
  const AIresponse = get(context, 'data.ai.aiResponse');

  const t = useI18n();

  useEffect(() => {
    bridge.emit('view:thanks');
  }, []);

  useEffect(() => {
    if (events && events.length) setShowEvents(true);
  }, [events]);

  const campaign = get(context, 'data.campaign');

  const thanksSettings = get(context, 'data.thanks', get(context, 'data.campaign.metadata.thanks', {}));

  const final_user = get(context, 'data.usuario_final');
  const isDark = context.ui.isDark;
  const poweredBySmileSrc = isDark ? poweredBySmileWhite : poweredBySmile;

  // Para mostrar nombre y apellido en el gracias (sirve para promos)
  const contact = get(context, 'data.contact');
  const store = get(context, 'data.store');
  const isExpired = get(context,'data.expired');
  const titleSettings = get(thanksSettings, 'title',t('thanks-opinion'));

  const title = useMemo(()=>{
    if(isExpired) return t('expired-poll');
    return replaceVariables(titleSettings,store,contact)
  },[t,isExpired, contact, store, titleSettings]);
  // const titleColor = getOneOf([campaign, device, encuesta], "metadata.theme.--response-color", "#000000");
  var subtitle = get(
    thanksSettings,
    'subtitle',
    t('goodbye')
  );
  // const subtitleColor = getOneOf([campaign, device, encuesta], "metadata.theme.--response-color", "#000000");
  const info = get(
    thanksSettings,
    'moreInfo',
    false
  );
  const infoHref = get(
    thanksSettings,
    'href',
    false
  );
  const logo = get(campaign, 'src') || logoDefault;
  const logoCustom = get(
    thanksSettings,
    'logoCustom',
    false
  );
  const titleSize = get(
    thanksSettings,
    'titleSize',
    '24px'
  );
  const subtitleSize = get(
    thanksSettings,
    'subtitleSize',
    '24px'
  );
  const moreInfoSize = get(
    thanksSettings,
    'moreInfoSize'
  );
  const spanSize = get(
    thanksSettings,
    'spanSize'
  );
  var text = get(thanksSettings, 'text', '');
  const textStyle = get(
    thanksSettings,
    'textStyle'
  );
  const textSize = get(thanksSettings, 'textSize');
  const textUnderline = get(textStyle, 'underline');
  const textColor = get(textStyle, 'color');

  const textHref = useMemo(() => {
    let href = get(textStyle,'href');
    if (!href) return;

    const regex = /{{\s*([^}]+?)\s*}}/g;
    const variables = href.match(regex);
    variables?.forEach((variable)=>{
      let path = variable.slice(2,variable.length-2);
      path = get(variablesToReplace, path, path);
      let value = get(context.data,path,get(context.data?.dataToSend,path,''));
      const pathArray = path.split('_');
      if(pathArray[0] === 'motives'){
        const answer = get(context.data,'dataToSend.answers',[]).find((answer)=>answer.id == pathArray[1]);
        if(answer){
          answer.motives.forEach((motive,index) => {
            const question = get(context.data,'poll.modules',[]).find((module)=>module.id==pathArray[1]);
            const motiveQuestion = get(question,'motives',[]).find((questionMotive) => questionMotive.id == motive.id);
            const name = motiveQuestion?.ui_name ? motiveQuestion.ui_name : motiveQuestion.name;
            value = `${value} ${name}${motive.comment?`-${motive.comment}`:''}`
            if(index!==answer.motives.length-1) value=`${value},`
          })
        }
      }

      href = href.split(variable).join(value);
    });
    return href;
  }, [context.data, textStyle]);

  const image = get(thanksSettings, 'image');
  const imageWidth = get(
    thanksSettings,
    'imageWidth'
  );
  const imageHeight = get(
    thanksSettings,
    'imageHeight',
    '100px'
  );
  const preFooterImages = get(thanksSettings, 'pre_footer_images', []);
  const showPoweredBySmile = get(
    thanksSettings,
    'showPoweredBySmile',
    true
  );
  const showLogo = get(
    thanksSettings,
    'showLogo',
    true
  );
  const btnColor = get(
    thanksSettings,
    'textStyle.hrefColor'
  );
  const textHrefColor = get(thanksSettings, 'textStyle.color');

  const marginTopThanksTitle = get(
    thanksSettings,
    '--marginTopThanksTitle'
  );
  const redesDefault = [
    {
      id: 'instagram',
      href: 'https://www.instagram.com/smile.latam/',
      span: '/smile.latam',
      class: 'smile21-instagram littleIcon',
    },
  ];
  const redes = instance?.settings?.socialNetworks ?? [];

  const showRedes = get(
    thanksSettings,
    'showRedes',
    true
  );
  const logoOnTop = get(
    thanksSettings,
    'logoOnTop',
    false
  );

  // Para mostrar hasta fecha fija (sirve para promos)
  const addToDateDefault = {
    days: 0,
    months: 0,
    years: 0,
  };

  const sendDate = get(final_user, 'created_at');
  const addToDateMetadata = get(
    thanksSettings,
    'addToDate'
  );
  // Si hay que sumar a la fecha:
  if (addToDateMetadata) {
    const addToDate = merge({}, addToDateDefault, addToDateMetadata);

    const addDays = (date, days) => {
      const copy = new Date(date.setDate(date.getDate() + days));
      return copy;
    };

    const addMonths = (date, months) => {
      const copy = new Date(date.setMonth(date.getMonth() + months));
      return copy;
    };

    const addYears = (date, years) => {
      const copy = new Date(date.setFullYear(date.getFullYear() + years));
      return copy;
    };

    const getFinalDate = (date, addToDate) => {
      var copy = date;
      copy = addDays(copy, addToDate.days);
      copy = addMonths(copy, addToDate.months);
      copy = addYears(copy, addToDate.years);
      return copy;
    };

    const getFormattedDate = (date) => {
      const copy = new Date(date);
      const month = copy.getMonth() + 1;
      const day = copy.getDate();
      const year = copy.getFullYear();
      return day + '/' + month + '/' + year;
    };

    const date = new Date(sendDate);
    const finalDate = getFormattedDate(getFinalDate(date, addToDate));

    if (
      text.indexOf('${fecha}') !== -1 ||
      subtitle.indexOf('${fecha}') !== -1
    ) {
      subtitle = subtitle.replace('${fecha}', finalDate);
      text = text.replace('${fecha}', finalDate);
    }
  }
  useEffect(() => {
    if (!context.data || Object.values(context.data).length === 0) return;
    const campaignType = get(campaign, 'type');
    if (campaignType === 'web') return;
    setTimeout(() => {
      const link = get(thanksSettings, 'redirect.link');
      if (campaignType !== 'tablet') {
        if (link) window.location.replace(link);
      } else {
        window.location.replace(
          `https://form.smileweb.net/form/${get(context, 'data.hash')}`
        );
      }
    }, get(thanksSettings, 'redirect.time', 5000));
  }, [campaign, context, thanksSettings]);

  const logoContainer = showLogo && (
    <div className={'logo-container'}>
      <img
        alt="logo"
        className={'logo'}
        src={logoCustom ? logo : isDark ? logoDefaultWhite : logoDefault}
      />
    </div>
  );

  const content = (
    <>
      <div className="thanks-header">{logoOnTop ? logoContainer : null}</div>
      <div className="thanks-body">
        {!AIresponse && <h3
          data-testid="thanks-title"
          className="texto thanksTitle"
          style={
            marginTopThanksTitle
              ? { marginTop: marginTopThanksTitle, fontSize: titleSize }
              : { fontSize: titleSize }
          }
        >
          {title}
        </h3>}

        {showEvents &&
          showingForm === undefined &&
          events.map((event, i) => (
            <div key={i}>
              <div>{event.strings.formPrompt}</div>
              <div>
                <Button
                  onClick={() => setShowingForm(event)}
                  style={{textTransform: 'none'}}
                  variant="outlined"
                  size="small"
                >
                  Inscribirme
                </Button>
              </div>
            </div>
          ))}

        {!AIresponse && <h3 data-testid="thanks-subtitle" className="texto thanksSubtitle" style={{ fontSize: subtitleSize }}>
          {subtitle}
        </h3>}

        {AIresponse && <h3 className={clsx('texto', cursorRemoved && 'cursor-removed', 'thanksTitle')} style={{padding: '20px', fontSize: titleSize}}>
          <TypewriterComponent
              onInit={tw => {
                  tw.typeString(AIresponse)
                  .callFunction(() => {
                    setCursorRemoved(true);
                  })
                  .start()
              }}
              options={{
                  delay: 45,
              }}
            />
        </h3>
        }

        {image && (
          <div className={'pregunta'}>
            <img
              alt="thanks"
              style={{ width: imageWidth, height: imageHeight }}
              src={image}
            />
          </div>
        )}
        {!replaceVariableError ? (
          textHref ? (
            // <a style={{ fontSize: textSize, color: textColor, textDecoration: textUnderline ? 'underline' : undefined }} href={textHref} target="_blank" >{text}</a>
            <ButtonShard
              testid="textHref"
              className={'boton'}
              href={textHref}
              target="_blank"
              style={{
                fontSize: textSize,
                alignSelf: 'center',
                maxWidth: 'none',
                backgroundColor: btnColor,
                color: textHrefColor
              }}
            >
              {text}
            </ButtonShard>
          ) : (
            <h3
              className="texto"
              style={{
                fontSize: textSize,
                color: textColor,
                textDecoration: textUnderline ? 'underline' : undefined,
                whiteSpace: 'pre-wrap',
              }}
              data-testid='simple-thanks-text'
            >
              {text}
            </h3>
          )
        ) : null}

        {!logoOnTop && logoContainer}
      </div>

      {preFooterImages.length > 0 && (
        <div className={'pregunta pre-footer'}>
          {preFooterImages.map((preFooterImage, index) => {
            const imageStyle = {
              marginLeft: '5px',
              marginRight: '5px',
              width: get(preFooterImage, 'width', null),
              height: get(preFooterImage, 'height', '100px'),
            };
            if(!get(preFooterImage, 'img', null)) return (<></>);
            return (
              <a href={get(preFooterImage, 'link', null)}>
                <img
                  alt={`thanks-pre-footer-img-${index}`}
                  style={imageStyle}
                  src={get(preFooterImage, 'img', null)}
                  key={`pre-footer-image-${index}`}
                />
              </a>
            );
          })}
        </div>
      )}

      <div className="thanks-footer">
        {info && infoHref ? (
          <ButtonShard
            className={'boton'}
            href={infoHref}
            target="_blank"
            style={{
              fontSize: moreInfoSize,
              maxWidth: 'none',
              backgroundColor: btnColor,
            }}
          >
            {info}
          </ButtonShard>
        ) : info ? (
          <div style={{ fontSize: moreInfoSize }} className="infoThanks">
            {info}
          </div>
        ) : null}

        {showRedes && (
          <div
            className={redes !== redesDefault ? 'multiplesRedes' : 'pregunta'}
          >
            {redes.map((carita) => (
              <div key={carita.id} style={{ margin: '15px' }}>
                <a href={carita.href}>
                  {/* <img alt={carita.id} className={redes !== redesDefault? ('bigRedes'):('redes')} src={carita.src}></img> */}
                  <i className={'icon ' + carita.class} />
                  <span style={{ fontSize: spanSize }} className={'spanIcon'}>
                    {carita.span}
                  </span>
                </a>
              </div>
            ))}
          </div>
        )}

        {showPoweredBySmile && (
          <div style={{ width: '100%' }}>
            <a data-testid="poweredBySmile" href={`https://smileweb.net/es?utm_source=smle&utm_medium=pb&utm_campaign=${get(context,'state.hash')}`} target="_blank" rel="noreferrer">
              <img className="poweredSmile" src={poweredBySmileSrc} alt='poweredSmile' />
            </a>
          </div>
        )}
      </div>
    </>
  );

  return <div data-testid="thanks" className="thanks-view">{content}</div>;
};

export default Thanks;
