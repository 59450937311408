import get from 'lodash/get';

const storeVariables = ['name'];
const contactVariables = ['first_name', 'last_name'];
export const variablesToReplace = {
  'store_name': 'store.caption',
};

export function replaceVariables(text, store, contact) {
  if(!text) return text;

  storeVariables.forEach((variable) => {
    text = text
      .split(`{{ store.${variable} }}`)
      .join(get(store, variable, null) != null ? store[variable] : '');
  });

  contactVariables.forEach((variable) => {
    text = text
      .split(`{{ contact.${variable} }}`)
      .join(get(contact, variable, null) != null ? contact[variable] : '');
  });

  return text;
}


export const BOLD_REGEX = /\*\*(.*?)\*\*/g;
export const ITALIC_REGEX = /\*(.*?)\*/g;
export const UNDERLINE_REGEX = /#(.*?)#/g;

export function replaceBold(match, texto) {
  return '<b>' + texto + '</b>';
}

export function replaceItalic(match, texto) {
  return '<i>' + texto + '</i>';
}

export function replaceUnderline(match, texto) {
  return '<u>' + texto + '</u>';
}

