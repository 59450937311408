import React from 'react';
import { useI18n } from '../utils/i18n';

const CommentFields = ({ encuesta, setUserData }) => {
  const t = useI18n();
  // const [input,setInput]=useState();
  // const keyboard =useRef();
  return (
    <div>
      <div style={{ fontSize: '18px' }} className={'pregunta'}>
        <label className={'preguntaComentario'}>
          {t(encuesta.strings.titleComments)}
        </label>
      </div>
      <div className={'pregunta caja'}>
        <textarea
          id="qwerty-mod"
          onChange={(ev) => setUserData({ comment: ev.target.value })}
          className={'input'}
        >
        </textarea>
        {/* <Keyboard
          value={input}
          name="keyboard"
          options={{
            type: 'textarea',
            layout: 'qwerty',
            alwaysOpen: true,
            usePreview: false,
            useWheel: false,
            stickyShift: false,
            appendLocally: true,
            color: 'light',
            updateOnChange: true,
            initialFocus: true,
            display: {
              accept: 'Submit',
            },
          }}
          onChange={(e)=>console.log(e)}
          onAccepted={(e)=> console.log(e)}
          ref={(k) => (keyboard = k)}
        /> */}
      </div>
    </div>
  );
};

export default CommentFields;
