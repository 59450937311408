import React, { useState } from 'react';
import get from 'lodash/get';
import '../assets/userDataForm.scss';
import { useStoreContext } from '../context/store';
import dayjs from "dayjs";
import MomentUtils from "@date-io/dayjs";
import "dayjs/locale/es";
import useWindowDimensions from "../utils/useWindowDimensions";
import SmileInput from './SmileInput';
import SmileSelect from './SmileSelect';
import { useI18n } from '../utils/i18n';
import moment from 'dayjs';

import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useMemo } from 'react';
import { replaceVariables } from '../utils/replaceVariables';
import Button from './ui/Button';
import Smilehelper from '../utils/Smilehelper';
import { AiFillCheckCircle } from 'react-icons/ai';

dayjs.locale("es");

const UserDataForm = ({ campaign }) => {

  const t = useI18n();
  const { context, dispatch, instance } = useStoreContext();
  const { autoStep } = instance.settings.nav;
  const { userData } = context;
  const [loading, setLoading] = useState(false);
  const { showErrors, store, contact } = context.data;
  const hash  = context.state?.hash;

  const title = useMemo(() => {
    return replaceVariables(get(campaign, 'metadata.form.title',''),store,contact);
  },[campaign, store, contact]);

  const titleSize = get(campaign, 'metadata.form.titleSize');
  const marginBottomTitle = get(campaign, 'metadata.form.marginBottomTitle', '10px');
  const marginTopTitle = get(campaign, 'metadata.form.marginTopTitle', '10px');
  const subtitle = useMemo(() => {
    return replaceVariables(get(campaign, 'metadata.form.subtitle',''),store,contact);
  },[campaign, store, contact])
  const subtitleSize = get(campaign, 'metadata.form.subtitleSize');
  const marginBottomSubtitle = get(campaign, 'metadata.form.marginBottomSubtitle', '20px');
  const footer = get(campaign, 'metadata.form.footer');
  const footerSize = get(campaign, 'metadata.form.footerSize');
  const marginTopFooter = get(campaign, 'metadata.form.marginTopFooter', '5px');
  const marginBottomFooter = get(campaign, 'metadata.form.marginBottomFooter', '5px');
  const metadata = get(campaign, 'metadata', {});
  const fields = get(metadata, 'form.fields', []);

  const values = get(context, 'userContactData').map(filter => ({ name: filter.name, value: filter.response, validated: filter.validated }));

  const invalidDate = false;
  const correctEmail = useMemo(() => get(userData, 'emailValidation', true), [userData]);

  const updateResponseContactData = (fieldName, value, validated = undefined) => {

    const payload = {
      fieldName,
      value,
    }

    if(validated!==undefined) payload.validated = validated;
    dispatch({
      type: 'userContactData.setResponse',
      payload: payload
    });
  }

  const haveErrors = () => {
    // VALIDATE CONTACTDATA IS COMPLETE FOR REQUIRED FIELDS
    const requiredContactData = context.userContactData.filter((filter) => filter.required === true);
    const invalidResponsesContactData = requiredContactData.reduce((ret, entry) => ret + ((entry.response === undefined || entry.response === "") ? 1 : 0), 0);

    if (invalidResponsesContactData > 0 || invalidDate) return true;
  }

  const { width } = useWindowDimensions();

  const validateEmail = (email) => {
    const validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(validRegex)) {
      dispatch({
        type: 'userData.update',
        payload: {
          emailValidation: true
        }
      });
    } else {
      dispatch({
        type: 'userData.update',
        payload: {
          emailValidation: false
        }
      });
    }
  }

  const handleChange = (value, field) => {
    updateResponseContactData(field.name, value);
    if((field.type === 'email' || field.name === 'email') && value) {
      validateEmail(value);
    }
    if((field.type === 'email' || field.name === 'email') && !value) {
      dispatch({
        type: 'data.update',
        payload: {
          showErrors: false,
        },
      });
      dispatch({
        type: 'userData.update',
        payload: {
          emailValidation: true
        }
      });
    }

    if(field.type === 'date' && field.name === 'birth_date') {
      dispatch({
        type: 'userData.update',
        payload: {
          birthdayValidation: (!value && !field.required) ? true : moment(value, 'DD/MM/YYYY', true).isValid()
        }
      });
    }
  }

  async function validateField(field,value){
    setLoading(true);
    if(!hash) return;
    const content = {};
    content[field] = value;
    try{
      await Smilehelper.validateForm(hash,content);
      updateResponseContactData(field, value, true);
      if(autoStep && fields.length === 1) {
        dispatch({
          type: 'ui.update',
          payload: {
            autoStepForm: true,
          },
        });
      }
    } catch(err){
      updateResponseContactData(field, value, false);
    } finally {
      setLoading(false);
    }

  }

  return (
    <div id='form' data-testid='userDataForm' className="userDataForm">
      <div style={{ fontSize: titleSize + 'px', marginTop: marginTopTitle + 'px', marginBottom: marginBottomTitle + 'px' }} className="title">{title}</div>
      <div style={{ fontSize: subtitleSize + 'px', marginBottom: marginBottomSubtitle + 'px' }} className="subtitle">{subtitle}</div>
      <div style={{ marginTop: '10px' }}></div>
      {fields.map((field,index) => {

        const contactField = values.find(filter => filter.name === field.name);
        const value = contactField?.value;
        const validated = contactField?.validated;

        if (field.type === 'text' || field.type === 'number' || field.type === 'email') {
          return (
            <div key={field.label} className="field">
              <SmileInput
                key={field.type}
                type={field.type}
                label={get(field,'custom')?field.label:t(field.name)}
                required={field.required}
                disabled={validated}
                value={value}
                onChange={(e) => {
                  if(validated){
                    handleChange(e.target.value, field, false)
                  } else {
                    handleChange(e.target.value, field)
                  }

                }}
                showError={(showErrors && field.required && !value) || (showErrors && correctEmail === false && field.name === 'email') || validated === false}
              />
              {field.validation &&
                <Button
                  onClick={()=> validateField(field.name, value)}
                  style={{marginLeft:'10px', display:'flex'}}
                  disabled={loading || validated}
                >
                  <span style={{marginRight:'5px'}}>{t('validate')}</span>
                  {validated && <AiFillCheckCircle/>}
                </Button>
              }
            </div>
          );
        }
        else if (field.type === 'select') {
          return (
            <div key={field.label} className="field">
              <SmileSelect
                dataTestId={`form${index}`}
                value={value}
                label={get(field,'custom')?field.label:t(field.name)}
                required={field.required}
                onChange={(ev) => {updateResponseContactData(field.name, ev.target.value)}}
                options={field.options}
                showError={showErrors && field.required && !value}
              />
            </div>
          );
        }
        else if (field.type === 'date') {
          return (
            <div data-testid={`form${index}`} key={field.label} className="field date">
              {width > 900 ? (
                <MuiPickersUtilsProvider key={field.label} utils={MomentUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    id={field.label}
                    key={field.label}
                    label={t(field.name)}
                    format="DD/MM/YYYY"
                    openTo={field.openTo ? field.openTo : "date"}
                    invalidDateMessage={t('invalid-date')}
                    maxDateMessage={t('maximum-date-exceeded')}
                    minDateMessage={t('minimum-date-exceeded')}
                    disableFuture={field.disableFuture ? field.disableFuture : false}
                    disablePast={field.disablePast ? field.disablePast : false}
                    inputVariant="outlined"
                    okLabel={t('accept')}
                    cancelLabel={t('cancel')}
                    value={values.find(filter => filter.name === field.name) && values.find(filter => filter.name === field.name)['value'] ? values.find(filter => filter.name === field.name)['value'] : null}
                    onChange={(ev) => handleChange(ev, field)}
                    allowKeyboardControl={false}
                    error={showErrors && field.required && !value}
                    required={field.required}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              ) : (

                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      margin="dense"
                      id={field.label}
                      label={t(field.name)}
                      format="DD/MM/YYYY"
                      openTo={field.openTo ? field.openTo : "date"}
                      invalidDateMessage="Fecha inválida"
                      maxDateMessage="Fecha máxima sobrepasada"
                      minDateMessage="Fecha mínima sobrepasada"
                      disableFuture={field.disableFuture ? field.disableFuture : false}
                      disablePast={field.disablePast ? field.disablePast : false}
                      inputVariant="outlined"
                      okLabel="Aceptar"
                      cancelLabel="Cancelar"
                      value={values.find(filter => filter.name === field.name) && values.find(filter => filter.name === field.name)['value'] ? values.find(filter => filter.name === field.name)['value'] : null}
                      onChange={(ev) => updateResponseContactData(field.name, ev)}
                      allowKeyboardControl={false}
                      error={showErrors && field.required && !value}
                      required={field.required}
                    />
                  </MuiPickersUtilsProvider>
                )
              }
            </div>
          )
        }
      })}

      {showErrors && haveErrors() ? (
        <div style={{ color: 'red' }}>{t('errors.complete-require-fields')} (*)</div>
      ) : null}

      {(showErrors && correctEmail === false) && (
        <div style={{ color: 'red' }}>{t('errors.enter-valid-email')}</div>
      )}

      <div style={{ width: '90%', margin: 'auto', fontSize: footerSize + 'px', marginTop: marginTopFooter + 'px', marginBottom: marginBottomFooter + 'px' }} className="footer">{footer}</div>

    </div>
  );
};

export default UserDataForm;
