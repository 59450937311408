import Button from './ui/Button';
import { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import { useStoreContext } from '../context/store';
import { AiOutlineDelete } from 'react-icons/ai';
import SmileAlert from './ui/Alert';

const Attachment = ({ answer, t }) => {
    const fileInput = useRef();
    const { context, instance } = useStoreContext();
    const { splitModuleComponents } = instance.settings.nav;
    const isIframeView = splitModuleComponents;
    const [imgBase64, setImgBase64] = useState(get(answer, 'src.src', ''));
    const fileName = get(context, `data.attachment[${get(answer, 'module.id')}].fileName`, '');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { dispatch } = useStoreContext();

    useEffect(() => {
        if(imgBase64) {
          answer.setSrc({ src: imgBase64, score: null, fileName: fileName });
        }
      }, [imgBase64, fileName]);

    const resetAttach = () => {
      answer.setSrc({ src: undefined, score: null, fileName: undefined });
    }

    const handleAttachment = async (e) => {
      dispatch({
        type: 'ui.update',
        payload: {
          working: true,
        },
      });
      const file = e.target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
        setError(t('invalid-format'));
        dispatch({
          type: 'ui.update',
          payload: {
            working: false,
          },
        });
        return;
      }
      if (file.size > 5242880) {
        setError(t('invalid-size'));
        dispatch({
          type: 'ui.update',
          payload: {
            working: false,
          },
        });
        return;
      }
      setLoading(true);
      const reader = new FileReader();
      reader.addEventListener('loadend', () => setImgBase64(reader.result));
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append('attachment', file);

      dispatch({
        type: 'data.update',
        payload: {
          attachment: {
            [answer.module.id]: {
              fileName: file.name
            }
          }
        }
      })
      setLoading(false);

      dispatch({
        type: 'ui.update',
        payload: {
          working: false,
        },
      });
    }

    const onClick = () => {
      if(error) {
        setImgBase64('');
        setError(false);
      }
      fileInput?.current?.click();
    }

    const handleDelete = () => {
      resetAttach();
      setImgBase64('');
    }

    return (
        <>
            <Button
              onClick={onClick}
              className='thin-button'
              disabled={imgBase64 && !error}
              style={{background: (imgBase64 && !error) ? '#8a8a8a' : undefined}}
            >
                {t('attach')}
            </Button>
            <input
                ref={fileInput}
                type="file"
                style={{ display: 'none' }}
                onClick={e => e.target.value = null}
                onChange={handleAttachment}
                accept='.png, .jpg, .jpeg'
            />

            {(imgBase64 && !error && !loading) && (
            <div className={`container-alert ${isIframeView ? 'container-alert-iframe' : ''}`}>
                <SmileAlert
                  type='success'
                  message={fileName.length < 20 ? fileName : `${fileName.substring(0, 19)}...`}
                >
                  <AiOutlineDelete onClick={handleDelete} size={18} style={{ cursor: 'pointer', display: 'flex' }}/>
                </SmileAlert>
            </div>)}

            <div className={isIframeView ? 'upload-image-iframe' : 'upload-image'}>
                {loading ? <></> : ( error //should to show a spinner
                    ? <div className='container-alert'>
                        <SmileAlert type='error' message={error} />
                    </div>
                    : (imgBase64 && (
                      <img
                        src={imgBase64}
                        alt='attached'
                        width={isIframeView ? 130 : 300}
                        height={isIframeView ? 100 : 230}
                      />
                    ))
                )}
            </div>
        </>
    );
}

export default Attachment;