import React, { useState } from 'react';
import get from 'lodash/get';
import carita_excelente from '../assets/csat-excelente.png';
import carita_bueno from '../assets/csat-bueno.png';
import carita_regular from '../assets/csat-regular.png';
import carita_mal from '../assets/csat-mal.png';
import carita_malisimo from '../assets/csat-malisimo.png';
import { useI18n } from '../utils/i18n';
import { cesColors, numericColors } from '../utils/numericColors';
import { Box } from '@material-ui/core';
import SmileRate from '../utils/SmileRate';
import { Legends } from './ui/Legends';


const getArray = (min, max, labels) => {
  let array = [];

  for (let i = 0; i <= max - min; i++) {
    array[i] = {
      id: i + min,
      label: labels[i],
    };
  }

  return array;
};

const QuestionScoreCES = ({ question, answer, setScore }) => {
  const t = useI18n();
  const min = get(question, 'metadata.settings.score.min', 1);
  const max = get(question, 'metadata.settings.score.max', 5);

  const [CESValue, setCESValue] = useState(
    ((get(answer, 'score') - 1) * (max - min)) / 9 + min
  );
  const labels = get(question, 'metadata.settings.score.labels', []);
  const order = get(question, 'metadata.settings.score.order', 'default');
  const customIconColor = get(question, 'metadata.settings.score.icon.color');
  const customIconSize = get(question, 'metadata.settings.score.icon.fontSize');
  const customIcon = get(
    question,
    'metadata.settings.score.icon.name',
    'StarFilled'
  );
  const array =
    order === 'reverse'
      ? getArray(min, max, labels).reverse()
      : getArray(min, max, labels);
  const layout = get(question, 'metadata.settings.score.layout', null); // Default Layout
  const showLabels = get(question, 'metadata.settings.score.showLabels', false);
  const sizeLabels = get(question, 'metadata.settings.score.sizeLabels', 14);

  const setCESScore = (voteValue) => {
    if (answer.disabled) return;
    const newValue = get(voteValue, 'id', voteValue);
    setCESValue(newValue);
    const score = ((newValue - min) * 9) / (max - min) + 1;
    setScore(score);
  };

  const best = order === 'reverse' ? labels[labels.length - 1] : labels[0];
  const worst = order === 'reverse' ? labels[0] : labels[labels.length - 1];

  const smiles = {
    5: [
      {
        alt: 'very-difficult',
        src: carita_malisimo,
      },
      {
        alt: 'hard',
        src: carita_mal,
      },
      {
        alt: 'indistinct',
        src: carita_regular,
      },
      {
        alt: 'easy',
        src: carita_bueno,
      },
      {
        alt: 'very-easy',
        src: carita_excelente,
      },
    ],
    7: [
      {
        alt: 'very-difficult',
        src: carita_malisimo,
      },
      {
        alt: 'hard',
        src: carita_mal,
      },
      {
        alt: 'something-difficult',
        src: carita_mal,
      },
      {
        alt: 'not-easy-not-difficult',
        src: carita_regular,
      },
      {
        alt: 'almost-easy',
        src: carita_bueno,
      },
      {
        alt: 'easy',
        src: carita_bueno,
      },
      {
        alt: 'very-easy',
        src: carita_excelente,
      },
    ],
  };

  return (
    <div style={{ margin: 'auto' }}>
      {(layout === 'smiles' || layout === 'numeric') ? (
        <div>
          <ul height="100px" className={`caritaStyle count-${array.length}`}>
            {array
              .slice(0)
              .reverse()
              .map((score) => {
                return (
                  <li className="smileContainer" key={score.id}>
                    {layout === 'numeric'
                      ? <div
                        className={`${answer && get(answer, 'score') === undefined
                          ? 'estadoInicial'
                          : answer &&
                            CESValue === score.id
                            ? 'select'
                            : 'sinselect-gray'} numeric-container ${array.length === 7 && 'numeric-container-7'}`
                        }
                        style={{ background: get(array.length === 7 ? cesColors : numericColors, `${score.id - 1}`), marginBottom: '10px' }}
                        data-testid={question.id + '' + score.id}
                        onClick={() => setCESScore(score, min, max)}
                      >
                        <h4 className={`${array.length === 7 && 'font-ces-7'}`}>{score.id}</h4>
                      </div>
                      : <img
                        data-testid={question.id + '' + score.id}
                        src={get(smiles[max][score.id - 1], 'src')}
                        alt={t(get(smiles[max][score.id - 1], 'alt'))}
                        className={
                          answer && get(answer, 'score') === undefined
                            ? 'estadoInicial'
                            : answer &&
                              answer.module.id === question.id &&
                              CESValue === score.id
                              ? 'select'
                              : 'sinselect-gray'
                        }
                        onClick={() => setCESScore(score, min, max)}
                      />}
                    {showLabels && <p className="labels">{t(score.label)}</p>}
                  </li>
                );
              })}
          </ul>
          {!showLabels && <Legends worst={worst} best={best} size={sizeLabels} />}
        </div>
      ) : layout === 'custom' ? (
        <>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <SmileRate
            value={CESValue}
            style={{ color: customIconColor, fontSize: customIconSize }}
            max={max}
            icon={customIcon}
            onChange={(value) => setCESScore(value, min, max)}
          />
        </Box>
          {!showLabels && <Legends worst={best} best={worst} size={sizeLabels} />}
        </>
      ) : (
        <div className={`cesInput ${layout}`}>
          {array.map((score) => {
            return (
              <div
                key={score.id}
                style={{
                  // display: 'flex', justifySelf: 'left',
                  cursor: 'pointer',
                }}
                data-testid={question.id + '' + score.id}
                onClick={() => setCESScore(score, min, max)}
                className={
                  answer && get(answer, 'score') === undefined
                    ? `style-${layout} estadoInicial`
                    : answer &&
                      answer.module.id === question.id &&
                      CESValue === score.id
                      ? `style-${layout} select`
                      : `style-${layout}`
                }
              >
                {(!layout || layout === 'options') &&
                  (answer &&
                    CESValue === score.id ? (
                    <i className={'smile21-radio-checked'} />
                  ) : (
                    <i className={'smile21-radio-unchecked'} />
                  ))}
                {t(score.label)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default QuestionScoreCES;
