import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import carita_excelente from '../assets/csat-excelente.png';
import carita_bueno from '../assets/csat-bueno.png';
import carita_regular from '../assets/csat-regular.png';
import carita_mal from '../assets/csat-mal.png';
import carita_malisimo from '../assets/csat-malisimo.png';
import { useI18n } from '../utils/i18n';
import { Legends } from './ui/Legends';
import { useTheme } from '../hooks/sdk';
import { numericColors } from '../utils/numericColors';
import SmileRate from '../utils/SmileRate';

const QuestionScoreCSAT = ({ question, answer, setScore }) => {
  const { smilesCsat: themeSmilesCsat } = useTheme();

  const min = get(question, 'metadata.settings.score.min', 1);
  const max = get(question, 'metadata.settings.score.max', 5);

  const [CSATValue, setCSATValue] = useState(
    ((get(answer, 'score') - 1) * (max - min)) / 9 + min
  );
  const customIconColor = get(question, 'metadata.settings.score.icon.color');
  const customIconSize = get(question, 'metadata.settings.score.icon.fontSize');
  const customIcon = get(
    question,
    'metadata.settings.score.icon.name',
    'StarFilled'
  );
  const showLabels = get(question, 'metadata.settings.score.showLabels', false);
  const sizeLabels = get(question, 'metadata.settings.score.sizeLabels', 14);
  const labels = get(question, 'metadata.settings.score.labels', []);
  const order = get(question, 'metadata.settings.score.order', 'default');
  const layout = get(question, 'metadata.settings.score.layout'); // Default Layout

  const options = useMemo(() => {
    return (order === 'reverse' || layout === 'custom')
      ? question.getScoreOptions({ ignoreSettings: true }).reverse()
      : question.getScoreOptions({ ignoreSettings: true });
  }, [order, question, layout]);

  const best = (order === 'reverse' || layout === 'custom') ? labels[labels.length - 1] : labels[0];
  const worst = (order === 'reverse' || layout === 'custom') ? labels[0] : labels[labels.length - 1];
  // const { array } = useMemo(() => {
  //   const newSmiles = [
  //     {
  //       alt: 'very-unsatisfied',
  //       src: themeSmilesCsat
  //         ? get(themeSmilesCsat[1], 'src', carita_malisimo)
  //         : carita_malisimo,
  //     },
  //     {
  //       alt: 'unsatisfied',
  //       src: themeSmilesCsat
  //         ? get(themeSmilesCsat[2], 'src', carita_mal)
  //         : carita_mal,
  //     },
  //     {
  //       alt: 'neither-satisfied-nor-dissatisfied',
  //       src: themeSmilesCsat
  //         ? get(themeSmilesCsat[3], 'src', carita_regular)
  //         : carita_regular,
  //     },
  //     {
  //       alt: 'satisfied',
  //       src: themeSmilesCsat
  //         ? get(themeSmilesCsat[4], 'src', carita_bueno)
  //         : carita_bueno,
  //     },
  //     {
  //       alt: 'very-satisfied',
  //       src: themeSmilesCsat
  //         ? get(themeSmilesCsat[5], 'src', carita_excelente)
  //         : carita_excelente,
  //     },
  //   ];

  //   // if (get(question, 'metadata.settings.allowScoreNA')){
  //   //   newArray.push({ id: null, label: 'n/a' });
  //   //   newSmiles.push({src:'image_na', alt:'n/a'});
  //   // }
  // }, [themeSmilesCsat]);

  const smiles = [
    {
      alt: 'very-unsatisfied',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[1], 'src', carita_malisimo)
        : carita_malisimo,
    },
    {
      alt: 'unsatisfied',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[2], 'src', carita_mal)
        : carita_mal,
    },
    {
      alt: 'neither-satisfied-nor-dissatisfied',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[3], 'src', carita_regular)
        : carita_regular,
    },
    {
      alt: 'satisfied',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[4], 'src', carita_bueno)
        : carita_bueno,
    },
    {
      alt: 'very-satisfied',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[5], 'src', carita_excelente)
        : carita_excelente,
    },
  ];
  const t = useI18n();

  const setCSatScore = (voteValue) => {
    if (answer.disabled) return;
    const newValue = get(voteValue, 'id', voteValue);
    setCSATValue(newValue);
    const score = ((newValue - min) * 9) / (max - min) + 1;
    setScore(score);
  };

  return (
    <div style={{ margin: 'auto' }}>
      {(layout === 'smiles' || layout === 'numeric') ? (
        <div>
          <ul height="100px" className={`caritaStyle count-${options.length}`}>
            {options
              .slice(0)
              .reverse()
              .map((option, i) => {
                return (
                  <li className="smileContainer" key={option.id}>
                    {layout === 'numeric'
                      ? <div
                        className={`${answer && get(answer, 'score') === undefined
                          ? 'estadoInicial'
                          : answer &&
                            answer.module.id === question.id &&
                            CSATValue === option.id
                            ? 'select'
                            : 'sinselect-gray'} numeric-container`}
                        style={{ background: numericColors[option.id - 1], marginBottom: '10px' }}
                        data-testid={question.id + '' + option.id}
                        onClick={() => setCSatScore(option, min, max)}
                      >
                        <h4>{option.id}</h4>
                      </div>
                      : <img
                        data-testid={question.id + '' + option.id}
                        src={get(smiles[option.id - 1], 'src')}
                        alt={t(get(smiles[option.id - 1], 'alt'))}
                        className={
                          answer && get(answer, 'score') === undefined
                            ? 'estadoInicial'
                            : answer &&
                              answer.module.id === question.id &&
                              CSATValue === option.id
                              ? 'select'
                              : 'sinselect-gray'
                        }
                        onClick={() => setCSatScore(option, min, max)}
                      />
                    }
                    {showLabels && <p className="labels">{t(option.label)}</p>}
                  </li>
                );
              })}
          </ul>
          {!showLabels && <Legends worst={worst} best={best} size={sizeLabels} />}
        </div>
      ) : layout === 'custom' ? (
        <>
          <SmileRate
            value={CSATValue}
            style={{ color: customIconColor, fontSize: customIconSize }}
            max={max}
            onChange={(value) => setCSatScore(value, min, max)}
            icon={customIcon}
          />
          {!showLabels && <Legends worst={worst} best={best} size={sizeLabels} />}
        </>
      ) : (
        <div className={`csatInput ${layout}`}>
          {options.map((score) => {
            const checked = answer && answer.module.id === question.id && CSATValue === score.id;
            return (
              <div
                key={String(score.id)}
                style={{
                  // display: 'flex', justifySelf: 'left',
                  cursor: 'pointer',
                }}
                data-testid={question.id + '' + score.id}
                onClick={() => setCSatScore(score, min, max)}
                className={
                  answer && get(answer, 'score') === undefined
                    ? `style-${layout} estadoInicial`
                    : answer &&
                      answer.module.id === question.id &&
                      CSATValue === score.id
                      ? `style-${layout} select`
                      : `style-${layout}`
                }
              >
                {(!layout || layout === 'options') &&
                  (checked ? (
                    <i className={'smile21-radio-checked'} />
                  ) : (
                    <i className={'smile21-radio-unchecked'} />
                  ))}

                {t(score.label)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default QuestionScoreCSAT;
